<template>
  <div>
    <!-- <div
      style="display: none"
      class="online"
    >
      <div
        class="row gx-3 gy-3 align-items-center mb-2 wrapper"
        v-for="(input, idx) in inputVal"
        :key="idx"
        :id="idx"  
      >

        <div class="col-lg-4 col-md-4 col-sm-4 col-4">
          <div class="input-wrapper">
            <select v-model="input.field" class="form-select" aria-label=".form-select-lg example" name="fields[]" id="fields">
              <option :value="option.value" v-for="(option, idx) in selectOptions.fields" :key="idx">{{ option.label }}</option>
            </select>
          </div>
        </div>

        <div class="col-lg-2 col-md-2 col-sm-2 col-2">
          <div class="input-wrapper">
            <select v-model="input.operator1" class="form-select" aria-label=".form-select-lg example" name="operator1[]" id="operator1">
              <option :value="option.value" v-for="(option, idx) in selectOptions.operator1" :key="idx">{{ option.label }}</option>
            </select>
          </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="input-wrapper">
            <input v-model="input.input" type="text" class="form-control" name="nilai[]" id="nilai" />
          </div>
        </div>

        <div class="col-lg-2 col-md-2 col-sm-2 col-2">
          <div class="input-wrapper">
            <select v-model="input.operator2" class="form-select" aria-label=".form-select-lg example" name="operator2[]" id="operator2">
              <option :value="option.value" v-for="(option, idx) in selectOptions.operator2" :key="idx">{{ option.label }}</option>
            </select>
          </div>
        </div>

        <div class="col-lg-1 col-md-1 col-sm-12">
          <button class="btn btn-danger hapus">
            <i class="bi bi-trash-fill"></i>
          </button>
        </div>

      </div>
	  </div> -->

    <!-- <form @submit.prevent="doAdvanceSerach"> -->
    <form>
      <section class="advance-search-hero mb-4">
        <div class="container">
          <div class="row">
            <h2 class="col-lg-8 col-md-7 col-sm-12">Pencarian lengkap</h2>
            <div class="d-flex d-lg-block col-lg-4 col-md-5 col-sm-12">
              <div class="d-flex justify-content-end">
                <button @click.prevent="addSearch" class="btn-add-filter btn btn-outline-ternary me-3">
                  Tambah Pencarian
                </button>
                <button @click.prevent="doAdvanceSerach" class="btn-search btn btn-ternary text-white" style="width: 9.375rem !important;" type="submit">
                  Cari
                </button>
              </div>
            </div>
          </div>
          <div class=" advance-search-filter mt-4 ">

            <div
              class="row gx-3 align-items-center mb-2 wrapper"
              v-for="(input, index) in inputVal"
              :key= "index"
              :id= "index"  
            >

              <div class="col-lg-10 col-md-10 col-sm-10 col-10 row" style="border-bottom: none;">

                <div class="col-lg-4 col-md-4 col-sm-8 col-8 mb-1">
                  <div class="input-wrapper">
                    <select v-model="input.fields" class="form-select form-select-lg form-select-sm" aria-label=".form-select-lg example">
                      <option :value="option.value" v-for="(option, idx) in selectOptions.fields" :key="idx">{{ option.label }}</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2 col-md-2 col-sm-4 col-4 mb-1">
                  <div class="input-wrapper">
                    <select v-model="input.operators" class="form-select form-select-lg form-select-sm" aria-label=".form-select-lg example">
                      <option :value="option.value" v-for="(option, idx) in selectOptions.operator1" :key="idx">{{ option.label }}</option>
                    </select>
                  </div>
                </div>

                <!-- pencarian fitur onProgress -->

                <!-- <div class="col-lg-3 col-md-3 col-sm-3 col-3" v-if="input.fields === 'pendidikan'">
                  <div class="input-wrapper d-flex">
                    <select v-model="input.keywords" class="form-select" aria-label=".form-select-lg example" search="true">
                      <option :value="option.value" v-for="(option, idx) in master.pendidikan" :key="idx">{{ option.pendidikan }}</option>
                    </select>
                    <el-select
                      v-model="input.keywords"
                      clearable
                      filterable
                      :placeholder="''"
                      no-match-text="Tidak ada data yang sesuai"
                      no-data-text="Tidak ada data"
                      remote
                      :remote-method="pendidikanMasterSearch"
                    >
                      <el-option v-for="(option, idx) in options" :key="idx" :value="option.pendidikan" :label="option.pendidikan"></el-option>
                    </el-select>
                  </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-3 col-3" v-else-if="selectOptionInput.includes(input.fields)">
                  <div class="input-wrapper d-flex">
                    <select v-model="input.splitters" class="form-select" aria-label=".form-select-lg example">
                      <option :value="option.value" v-for="(option, idx) in selectOptions.operator2" :key="idx">{{ option.label }}</option>
                    </select>
                    <el-select v-model="input.splitters" clearable filterable :placeholder="''" no-match-text="Tidak ada data yang sesuai" no-data-text="Tidak ada data">
                      <el-option v-for="(option, idx) in master[input.fields]" :key="idx" :value="option[input.fields]" :label="`(${option.kode}) ${option[input.fields]}`"></el-option>
                    </el-select>
                  </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-3 col-3" v-else>
                  <div class="input-wrapper">
                    <input v-model="input.keywords" type="text" class="form-control"/>
                  </div>
                </div> -->
                
                <!-- pencarian fitur onProgress -->

                <div class="col-lg-4 col-md-4 col-sm-8 col-8 mb-1">
                  <div class="input-wrapper">
                    <input v-model="input.keywords" type="text" class="form-control"/>
                  </div>
                </div>

                <!-- pencarian fitur onProgress -->

                <div class="col-lg-2 col-md-2 col-sm-4 col-4 mb-1">
                  <div class="input-wrapper">
                    <select v-model="input.splitters" class="form-select" aria-label=".form-select-lg example">
                      <option :value="option.value" v-for="(option, idx) in selectOptions.operator2" :key="idx">{{ option.label }}</option>
                    </select>
                  </div>
                </div>

              </div>

              <div class="col-lg-2 col-md-2 col-sm-2 col-2" v-if="index !== 0">
                <button class="btn btn-danger hapus" @click.prevent="deleteVal(index)">
                  <!-- <i class="bi bi-trash-fill"></i> -->
                  <svg xmlns="http://www.w3.org/2000/svg" :width="16" :height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                  </svg>
                </button>
              </div>

            </div>

          </div>
        </div>
      </section>
    </form>

    <!-- Content -->
    <div class="container">
      <div class="mt-3">
        Menampilkan <strong> {{ total }} </strong>
      </div>
      <div class="card shadow-sm border-0 mb-3">
        <div class="card-body" style="overflow: auto">
          
          <table class="table table-search" style="width:100%">

            <thead>
              <tr>
                <th
                  v-for="(header, idx) in tableHeader"
                  :key="idx"
                  :class="header.label === 'Kode Jabatan' ? 'border-bottom-0' : 'border-bottom-0'"
                >
                  <h4 class="fw-semibold">{{ header.label }}</h4>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr class="align-items-center tbody-tr"
                v-for="(tData, idx) in data"
                :key="idx"
              >
                <td
                  v-for="header in tableHeader"
                  :key="header.id"
                  :class="header.prop == 'kode_jabatan' ? 'text-center' : header.prop == 'nama_jabatan' ? '' : 'text-left' "
                >
                  <a v-if="header.prop == 'nama_jabatan'"
                    class="text-ternary text-decoration-none fw-medium"
                    @click="toJabatan(tData)"  
                  >
                    <h5 style="cursor: pointer;">{{ tData[header.prop] }}</h5>
                  </a>
                  <h5 v-else>{{ tData[header.prop] }}</h5>
                </td>
              </tr>

              <tr v-if="(data.length < 1 || data === '') && !isLoading">
                <td colspan="4" class="text-center">Tidak Ada Data</td>
              </tr>

              <tr v-show="isLoading">
                <td colspan="4">
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border"></div>
                  </div>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
      </div>
    </div>

    <!-- </form> -->
  </div>
</template>

<script>
import api from './api.js'
import swal from 'sweetalert2'
import { Select, Option, Loading } from 'element-ui'

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Loading.name]: Loading,
  },
  data () {
    return {
      isLoading: false,
      selectOptions: {
        fields: [
          {
            label: '',
            value: ''
          },
          {
            label: 'Kategori/Lapangan Usaha',
            value: 'sektor'
          },
          {
            label: 'Golongan Pokok',
            value: 'subsektor'
          },
          {
            label: 'Kode KBLI',
            value: 'kode_kbli'
          },
          {
            label: 'Nama Jabatan',
            value: 'nama_jabatan'
          },
          {
            label: 'Kode Jabatan',
            value: 'kode_jabatan'
          },
          {
            label: 'Padanan Nama',
            value: 'padanan_nama_jabatan'
          },
          {
            label: 'Ringkasan Uraian Tugas',
            value: 'ringkasan_uraian_tugas'
          },
          {
            label: 'Uraian Tugas',
            value: 'uraian_tugas'
          },
          {
            label: 'Pendidikan',
            value: 'pendidikan'
          },
          {
            label: 'Pengetahuan Kerja',
            value: 'pengetahuankerja'
          },
          {
            label: 'Pelatihan Kerja',
            value: 'pelatihankerja'
          },
          {
            label: 'Syarat Fisik',
            value: 'syaratfisik'
          },
          {
            label: 'Temperamen',
            value: 'temperamen'
          },
          {
            label: 'Bakat',
            value: 'bakat'
          },
          {
            label: 'Minat',
            value: 'minat'
          },
        ],
        operator1: [
          {
            label: 'Like %%',
            value: 'like'
          },
          {
            label: 'Equal to',
            value: '='
          },
          {
            label: 'Greater than',
            value: '>'
          },
          {
            label: 'Less than',
            value: '<'
          },
        ],
        operator2: [
          {
            label: 'And',
            value: 'and'
          },
          {
            label: 'Or',
            value: 'or'
          },
        ]
      },
      inputVal: [
        {
          fields: '',
          operators: 'like',
          splitters: 'and',
          keywords: ''
        }
      ],
      tableHeader: [
        {
          id: 1,
          label: 'Kode Jabatan',
          prop: 'kode_jabatan'
        },
        {
          id: 2,
          label: 'Kategori/Lapangan Usaha',
          prop: 'sector'
        },
        {
          id: 3,
          label: 'Sub Kategori/Lapangan Usaha',
          prop: 'subsector'
        },
        {
          id: 4,
          label: 'Nama Jabatan',
          prop: 'nama_jabatan'
        }
      ],
      selectOptionInput: [
        'pendidikan',
        'syaratfisik',
        'temperamen',
        'bakat',
        'minat'
      ],
      master: {
        pendidikan: [],
        syaratfisik: [],
        bakat: [],
        minat: [],
        temperamen: []
      },
      options: [],
      data: [],
      currPage: 1,
      total: 0,
      lastPage: 2,
      limit: 25,
      limitSelectOptions: [25, 50],
      nextPageAddress: null
    }
  },
  mounted () {
    console.log('route', this.$route)
    this.getMaster()
    this.getQuickSearch()
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    addSearch () {
      const currVal = JSON.parse(JSON.stringify(this.inputVal))
      currVal.push({
        fields: '',
        operators: 'like',
        splitters: 'and',
        keywords: ''
      })
      this.inputVal = JSON.parse(JSON.stringify(currVal))
    },
    deleteVal (idx) {
      this.inputVal.splice(idx, 1)
    },
    doAdvanceSerach () {
      const isAdvanceSearch = this.isAdvanceSearch()
      if (isAdvanceSearch === false) {
        swal.fire({
          icon: 'warning',
          title: 'Lengkapi pencarian lengkap'
        })
        return ''
      }

      this.currPage = 1
      this.total = 0
      this.lastPage = 2
      this.nextPageAddress = null
      this.data = []

      this.getAdvanceSearch()
    },
    async getQuickSearch () {
      if (this.isLoading) return ''
      if (this.currPage >= this.lastPage) return ''
      // if (this.currPage >= this.lastPage) return ''
      let notFirstFetch = true
      this.isLoading = true
      let param = { limit: this.limit }
      const keyword = this.quickSearchVal
      // const { data } = await api.quickSearch2(this.quickSearchVal, this.limit, page)
      // this.data = JSON.parse(JSON.stringify(this.data)).concat(JSON.parse(JSON.stringify(data)))
      
      let res = {}
      try {
        if (this.nextPageAddress !== '' && this.nextPageAddress !== null) {
          if (keyword) {
            res = await api.quickSearchPhpKeyword(param, keyword, this.nextPageAddress)
          } else {
            res = await api.quickSearchPhpNonKeyword(param, this.nextPageAddress)
          }
        } else {
          if (keyword) {
            res = await api.quickSearchPhpKeyword(param, keyword)
          } else {
            res = await api.quickSearchPhpNonKeyword(param)
          }
          notFirstFetch = false
          this.currPage = 1
        }

        if (res.data === '' || res.status === 204) {
          this.data = []
          return []
        } else {
          this.lastPage = res.data.last_page
          const data = res.data.data
          this.nextPageAddress = res.data.next_page_url
          this.total = res.data.total

          if (data === undefined) {
            this.isLoading = false
            return ''
          }
          
          if (notFirstFetch) {
            this.data = [ ...JSON.parse(JSON.stringify(this.data)), ...JSON.parse(JSON.stringify(data)) ]
          } else {
            this.data = JSON.parse(JSON.stringify(data))
          }
        }
      } catch (err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Gagal melakukan pencarian'
        })
      } finally {
        this.isLoading = false
      }
    },
    async getAdvanceSearch () {
      if (this.isLoading) return ''
      if (this.currPage >= this.lastPage) return ''
      // if (this.currPage >= this.lastPage) return ''
      let notFirstFetch = true
      this.isLoading = true
      const advanceParams = this.getAdvanceSearchParams()
      let param = { limit: this.limit, ...advanceParams }
      
      // const keyword= this.quickSearchVal
      // const { data } = await api.quickSearch2(this.quickSearchVal, this.limit, page)
      // this.data = JSON.parse(JSON.stringify(this.data)).concat(JSON.parse(JSON.stringify(data)))
      
      let res = {}
      try {
        if (this.nextPageAddress !== '' && this.nextPageAddress !== null) {
          res = await api.advanceSearch(param, this.nextPageAddress)
        } else {
          res = await api.advanceSearch(param)
        }

        if (res.data === '' || res.status === 204) {
          this.data = []
          return []
        } else {
          this.lastPage = res.data.last_page
          const data = res.data.data
          this.nextPageAddress = res.data.next_page_url
          this.total = res.data.total

          if (data === undefined) {
            this.isLoading = false
            return ''
          }
          
          if (notFirstFetch) {
            this.data = [ ...JSON.parse(JSON.stringify(this.data)), ...JSON.parse(JSON.stringify(data)) ]
          } else {
            this.data = JSON.parse(JSON.stringify(data))
          }
        }
      } catch (err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          // title: 'Error',
          text: 'Gagal melakukan pencarian lengkap'
        })
      } finally {
        this.isLoading = false
      }
    },
    getAdvanceSearchParams () {
      const data = { fields: [], keywords: [], operators: [], splitters: [] }

      this.inputVal.forEach(d => {
        if (d.fields !== '' && d.fields !== null && d.keywords !== '' && d.keywords !== null) {
          data.fields.push(d.fields)
          data.operators.push(d.operators)
          data.splitters.push(d.splitters)
          data.keywords.push(d.keywords)
        }
      })
      return data
    },
    isAdvanceSearch () {
      let advanceSearch = true
      this.inputVal.forEach(d => {
        if (d.fields === '' || d.fields === null || d.keywords === '' || d.keywords === null) {
          advanceSearch = false
        }
      })
      return advanceSearch
    },
    async getMaster () {
      try {
        const res = await api.phpGetSuntingMaster()

        this.master.pendidikan = JSON.parse(JSON.stringify(res[0].data.map(data => { return { ...data, label: 'pendidikan' } }) ))
        this.options = JSON.parse(JSON.stringify(res[0].data.map(data => { return { ...data, label: 'pendidikan' } }).slice(0, 50) ))
        this.master.syaratfisik = JSON.parse(JSON.stringify(res[1].data.map(data => { return { ...data, label: 'syaratfisik' } })))
        this.master.bakat = JSON.parse(JSON.stringify(res[2].data.map(data => { return { ...data, label: 'bakat' } })))
        this.master.minat = JSON.parse(JSON.stringify(res[3].data.map(data => { return { ...data, label: 'minat' } })))
        this.master.temperamen = JSON.parse(JSON.stringify(res[4].data.map(data => { return { ...data, label: 'tempramen' } })))
      } catch (err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          // title: '',
          text: 'Gagal mendapatkan master data'
        })
      }
    },
    async pendidikanMasterSearch (query) {
      try {
        if (query == '') {
          this.options = this.master.pendidikan.slice(0, 50)
          return ''
        }
        this.options = this.master.pendidikan.filter(data => {
          if (data.pendidikan.toLowerCase().includes(query.toLowerCase()))
          return data
        }).slice(0, 50)
      } catch (err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error when fetching sunting remote search pendidikan'
        })
      }
    },
    async toJabatan (jabatan) {
      console.log('jabatan', jabatan)
      if (jabatan && jabatan !== null && jabatan !== undefined) {
        // this.$router.push(`/home/detail/${jabatan.id}`)
        window.open(`/home/detail/${jabatan.id}`)
      }
    },
    async handleScroll () {
      if (this.isLoading || this.currPage >= this.lastPage || this.data.length === 0 ) return ''

      const deviation = 5
      let scrollHeight = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight
      let bottomOfWindow = ((scrollHeight - deviation) <= document.documentElement.offsetHeight) && (document.documentElement.offsetHeight <= (scrollHeight + deviation))

      if (bottomOfWindow) {
        this.currPage = this.currPage + 1
        this.offset = this.offset + this.limit
        // await this.getQuickSearchExpress()

        // php
        const isAdvanceSearch = this.isAdvanceSearch()
        if (isAdvanceSearch) {
          await this.getAdvanceSearch()
        } else {
          await this.getQuickSearch(this.currPage)
        }
      }
    },
  }
}
</script>

<style>
  @import url('../../assets/styles/general.css');
  @import url('../../assets/styles/advance-search.css');
  @import url('../../assets/styles/navbar.css');
  @import url('../../assets/styles/hero.css');
  @import url('../../assets/styles/quick-search.css');

  .table-search thead {
    background-color: #EDF2F7;
  }

  .el-input {
    width: 100% !important;

  }

  .el-input .el-input__inner {
    background: transparent !important;
  }

  td {
    word-wrap: break-word;
    word-break: normal;
  }

  th {
    word-wrap: break-word;
    word-break: normal;
  }
</style>